<!--Tudo que for usar dentro do projeto todo pega daqui. Para qualquer página..-->

<template>
  <div>
   <router-view></router-view>
  </div>
</template>

<script>
export default {
  
}
</script>