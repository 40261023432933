const Home = () =>  import('@/pages/Home')
const Products = () =>  import('@/pages/Products')
const Cart = () =>  import('@/pages/Cart')
const Checkout = () =>  import('@/pages/Checkout/Checkout')
const Register = () =>  import('@/pages/Auth/Register')
const Login = () =>  import('@/pages/Auth/Login')
const ForgotPassword = () =>  import('@/pages/Auth/ForgotPassword')
const CheckTokenToForgotPassword = () =>  import('@/pages/Auth/CheckTokenToForgotPassword')
const ResetPassword = () =>  import('@/pages/Auth/ResetPassword')
const MyOrders = () =>  import('@/pages/Auth/MyOrders')
const DetailOrder = () =>  import('@/pages/Auth/DetailOrder')
const EditClient = () =>  import('@/pages/Auth/Edit')
const EditAddress = () =>  import('@/pages/Auth/EditAddress')
const AboutTenant = () =>  import('@/pages/About')
const PageNotFound = () =>  import('@/pages/PageNotFound')
const Help = () =>  import('@/pages/Institutional/Help')
const About = () =>  import('@/pages/Institutional/About')
const Benefits = () =>  import('@/pages/Institutional/Benefits')
const Privacy = () =>  import('@/pages/Institutional/Privacy')
const SignPlan = () =>  import('@/pages/Institutional/SignPlan')
const Term = () =>  import('@/pages/Institutional/Term')


// import Home from '@/pages/Home'
// import Products from '@/pages/Products'
// import Cart from '@/pages/Cart'
// import Checkout from '@/pages/Checkout/Checkout'
// import Register from '@/pages/Auth/Register'
// import Login from '@/pages/Auth/Login'
// import ForgotPassword from '@/pages/Auth/ForgotPassword'
// import CheckTokenToForgotPassword from '@/pages/Auth/CheckTokenToForgotPassword'
// import ResetPassword from '@/pages/Auth/ResetPassword'
// import MyOrders from '@/pages/Auth/MyOrders'
// import DetailOrder from '@/pages/Auth/DetailOrder'
// import EditClient from '@/pages/Auth/Edit'
// import EditAddress from '@/pages/Auth/EditAddress'
// import AboutTenant from '@/pages/About'
// import PageNotFound from '@/pages/PageNotFound'
// import Help from '@/pages/Institutional/Help'

// páginas footer
// import About from '@/pages/Institutional/About'
// import Benefits from '@/pages/Institutional/Benefits'
// import Privacy from '@/pages/Institutional/Privacy'
// import SignPlan from '@/pages/Institutional/SignPlan'
// import Term from '@/pages/Institutional/Term'




const routes = [
  {
    path: '/login',
    component: Login,
    name: 'login',
    meta: {
      title: 'Login'
    },
  },
  {
    path: '/register',
    component: Register,
    name: 'register',
    meta: {
      title: 'Registra-se'
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgot.password',
    meta: {
      title: 'Esqueci minha senha'
    },
  },
  {
    path: '/forgot-password-token',
    component: CheckTokenToForgotPassword,
    name: 'check.token.forgotPassword',
    meta: {
      title: 'Esqueci minha senha'
    },
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    name: 'reset.password',
    meta: {
      title: 'Redefinir senha'
    },
  },

  {
    path: '/checkout',
    component: Checkout,
    name: 'checkout',
    meta: {
      title: 'Checkout'
    },
  },

  {
    path: '/',
    component: () => import('@/layouts/DefaultTemplate'),
    children: [
      {
        path: '/',
        component: Home,
        name: 'home',
        meta: {
          title: 'Home - Restaurantes'
        },
      },
      {
        path: '/loja/:companyUrl/:uuid',
        component: Products,
        name: 'products',
        props: true,
        meta: {
          title: 'Produtos'
        },
      },
      {
        path: '/carrinho',
        component: Cart,
        name: 'cart',
      },
      {
        path: '/meus-pedidos',
        component: MyOrders,
        name: 'my.orders',
        meta: {
          title: 'Meus pedidos'
        },
      },

      {
        path: '/pedido/:identify',
        component: DetailOrder,
        name: 'detail.order',
        props: true,
        meta: {
          title: 'Pedido'
        },
      },

      {
        path: '/auth/edit/',
        component: EditClient,
        name: 'auth.edit',
        props: true,
        meta: {
          title: 'Editar dados'
        },
      },

      {
        path: '/auth/edit/address',
        component: EditAddress,
        name: 'auth.edit/address',
        props: true,
        meta: {
          title: 'Editar endereço'
        },
      },



      
      



      {
        path: '/loja/:companyUrl/:uuid/about',
        component: AboutTenant,
        name: 'about_tenant',
        props: true,
        meta: {
          title: 'sobre'
        },
      },
      {
        path: '/about',
        component: About,
        name: 'about',
        meta: {
          title: 'sobre'
        },
      },
      {
        path: '/benefits',
        component: Benefits,
        name: 'benefits',
        meta: {
          title: 'Benefícios'
        },
      },
      {
        path: '/signPlan',
        component: SignPlan,
        name: 'sign_plan',
        meta: {
          title: 'Vem com a gente'
        },
      },
      {
        path: '/privacy',
        component: Privacy,
        name: 'privacy',
        meta: {
          title: 'Privacidade'
        },
      },
      {
        path: '/term',
        component: Term,
        name: 'term',
        meta: {
          title: 'Termos'
        },
      },
      {
        path: '/help',
        component: Help,
        name: 'help',
        meta: {
          title: 'Ajuda'
        },
      },
      
      

      {
        path: '*',
        component: PageNotFound,
        meta: {
          title: 'Página não encontrada'
        },
      }
    ]
  },

]

export default routes