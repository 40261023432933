import { api } from "@/bootstrap/index";
import { API_VERSION } from '@/configs/api'


export default {
  state: {
    products: [],
    loja: {},
  },

  mutations: {
    ADD_PRODUCT_CART(state, payload) {
      state.products.push({
        qty: payload.qty,
        product: payload.product,
        complements: payload.complements,
        identify: payload.product.identify,
        comment: payload.comment,
        totalPrice: payload.totalPrice,
      })
      state.loja = Object.assign(state.loja, payload.loja)
    },

    EDIT_PRODUCT_CART(state, payload) {
      state.products[payload.indexProd].qty = payload.qty
      state.products[payload.indexProd].comment = payload.comment
      state.products[payload.indexProd].totalPrice = payload.totalPrice
      state.products[payload.indexProd].complements = payload.complements
    },

    REMOVE_PRODUCT_CART(state, index) {
      state.products.splice(index, 1)
      if (!state.products.length) {
        state.loja = {}
      }
    },

    CLEAR_CART(state) {
      state.products = [],
        state.loja = {}
    },

    SET_COMPANY_CART(state, company) {
      state.loja = Object.assign(state.loja, company.data)
    },
  },
  actions: {
    searchCurrentStoreInCart({ commit, state }) {
      if (state.loja.uuid) {
        return api.get(`${API_VERSION}/tenants/${state.loja.uuid}`)
          .then(response => commit('SET_COMPANY_CART', response.data))
      }
    }

  },

  getters: {
    totalComplements(state, getters) {
      let total = 0;
      state.products.map((item, index) => {
        item.complements.map(ele => {
          total = total + ele.optionals.reduce((acc, option) => {
            acc += option.qtd * option.price
            return acc
          }, 0)

        })
      });
      return total;

    },

    subTotalCart(state, getters) {
      let subTotal = 0;
      state.products.map((item, index) => {
        subTotal += item.qty * item.product.price;
      });
      return subTotal + getters.totalComplements;
      
    },
    
    totalCart(state, getters) {
      let total = 0;
      if (getters.taxaFrete === 0) {
        return (total += getters.subTotalCart);
      }
      total += getters.subTotalCart + state.loja.deliveryFee;
      return total;
    },
    taxaFrete(state, getters) {
      if (state.loja.customFreedeliveryForOrdersAbove === 0) {
        return state.loja.deliveryFee;
      }

      if (getters.subTotalCart >= state.loja.minimumOrderValueDelivery) {
        return 0;
      }
      return state.loja.deliveryFee;
    },


  },
}

