import { api } from "@/bootstrap/index";
import { TOKEN_NAME, API_VERSION } from '@/configs/api'

export default {
  state: {
    me: {
      name: '',
      email: '',
      addresses: [],
      defaultAddress: {}
    },
  },
  mutations: {
    SET_ME(state, me) {
      state.me = me
      state.me.defaultAddress = me.addresses.filter(item => item.default_address)[0]
    },

    SET_ADDRESS(state, address) {
      state.me.addresses.push(address)
    },

    UPDATE_ADDRESS(state, address) {
      state.me.addresses.filter((item, index) => {
        if(item.id === address.id){
          state.me.addresses[index].street = address.street,
          state.me.addresses[index].neighborhood = address.neighborhood,
          state.me.addresses[index].number = address.number,
          state.me.addresses[index].complement = address.complement,
          state.me.addresses[index].reference_point = address.reference_point
          state.me.addresses[index].default_address = address.default_address
         // state.me.addresses = Object.assign(state.me.addresses, address)
          
        }
      })
    },
    
    REMOVE_ADDRESS(state, address) {
      state.me.addresses.filter((item, index) => {
        if(item.id === address.id){
          state.me.addresses.splice(index,1)
        }
      })
    },

    SET_ADDRESS_DEFAULT(state, address){
      state.me.defaultAddress = address
    },

    LOGOUT(state) {
      state.me = {
        name: '',
        email: '',
        addresses: [],
        defaultAddress: {}
      }
    }

  },
  actions: {
    register({ commit,dispatch }, params) {
      return api.post('auth/register', params)
      .then(response => {
        localStorage.setItem(TOKEN_NAME, response.data.data.token)
        dispatch('getMe')
      })
    },

    login({ commit, dispatch }, params) {
      return api.post('auth/token', params)
        .then(response => {
          localStorage.setItem(TOKEN_NAME, response.data.token)
          dispatch('getMe')
        })
    },

    getMe({ commit }) {
      if(localStorage.getItem('token_sanctum')){
        return api.get('auth/me')
        .then(response => {
          commit('SET_ME', response.data.data)
          return  response.data.data
        }).catch(error => {
          localStorage.removeItem(TOKEN_NAME)
        })
      }

    },

    edit({ commit }, params) {
      return api.put(`auth/edit/${params.id}`, params).then(response => {
        commit('SET_ME', response.data.data)
       return response.data
      })
    },

    createAddresses({ commit }, params) {
      return api.post(`auth/addresses`, params)
        .then(response => {
          commit('SET_ADDRESS', response.data.data)
        })
    },

    editAddresses({ commit }, params) {
      return api.put(`auth/address/${params.id}`, params).then(response => {
        commit('UPDATE_ADDRESS', response.data.data)
        return response.data
      })
    },
    
    deleteAddresses({ commit }, id) {
      return api.delete(`auth/address/${id}`).then(response => {
        commit('REMOVE_ADDRESS', response.data.data)
        return response.data
      })
    },

    SetdAddressSelected({ commit }, id) {     
     return api.put(`auth/address/${id}/setdefault`).then(response => {
       commit('SET_ADDRESS_DEFAULT', response.data.data)
     })
    },

    

    logout({ commit }) {
      return api.post('auth/logout')
        .then(response => {
          commit('LOGOUT')
         localStorage.removeItem(TOKEN_NAME)
        })
    },

    forgotPassword({ commit }, params) {
      return api.post('forgot/password', params)
    },

    validateResetCode({ commit }, params) {
      return api.post('validate-reset-code', params)
      .then(response => {
        commit('LOGOUT')
       localStorage.removeItem(TOKEN_NAME)
      })
      
    },

    resetPassword({ commit,dispatch }, params) {
      return api.put('reset/password', params)
      .then(response => {
        localStorage.setItem(TOKEN_NAME, response.data.token)
        dispatch('getMe')
      })
    },

    
    

  },
  getters: {

  },
}

