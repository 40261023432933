import { api } from "@/bootstrap/index";
import { TOKEN_NAME, API_VERSION } from '@/configs/api'

const state = {
  myOrders: {
    data: []
  }
}

const mutations = {
  SET_ORDERS(state, orders) {
    state.myOrders = orders
  },

  SET_ORDER(state, order) {
    state.myOrders.data = order
  }
}

const actions = {
  getMyOrders({ commit }) {
    return api.get(`auth/${API_VERSION}/my-orders`)
      .then(response => {
        commit('SET_ORDERS', response.data)
      })
  },

  getOrderIdentify({ commit }, identify) {
    return api.get(`auth/${API_VERSION}/orders/${identify}`, {
    })
  },

  evaluationOrder({ commit }, params) {
    return api.post(`auth/${API_VERSION}/orders/${params.identify}/evaluations`, params)
  },

  createOrder({ commit }, params) {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem(TOKEN_NAME)
      if (!token) reject()

      api.post(`auth/${API_VERSION}/orders`, params, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => {
          commit('CLEAR_CART')
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
    })
  }
}

export default {
  state,
  mutations,
  actions
}