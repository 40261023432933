import { api } from "@/bootstrap/index";

export default {
  actions: {
    aceitLgpd({ commit }, params) {
      return api.post("lgpd", params).then((response) => {
         localStorage.setItem("lgpd", response.data.lgpd);
      });
    },
  },
};
