const state = {
  items:{
    data:[]
  },
  companySelected:{
   name:'',
   products:[],
   tenantOpen: ''
  },
  categoriesCompanySelected:[],
  
 }
export default  state 
