require('./bootstrap')
require('./plugins')

import Vue from 'vue'
import BaseTemplate from './layouts/BaseTemplate'
import router from './routes'
import store from './store'

import './assets/css/tailwind.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(BaseTemplate),
  router,
  store
}).$mount('#app')

 store.dispatch('getMe')
 store.dispatch('searchCurrentStoreInCart')
 