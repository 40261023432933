import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import companies from './modules/companies/index'
import cart from './modules/cart/index'
import auth from './modules/auth/index'
import orders from './modules/orders/index'
import lgpd from './modules/lgpd/index'

Vue.use(Vuex)

const dataState = createPersistedState({
  key: 'cart_client',
  paths: ['cart', 'auth.me.defaultAddress']
})

const store = new Vuex.Store({
  modules:{
    companies,
    cart, 
    auth,
    orders,
    lgpd,
  },
  plugins: [dataState]
})

export default store