import { URI_BASE_API, API_VERSION } from '../configs/api'
import axios from 'axios'

export const api = axios.create({
  baseURL: URI_BASE_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})


api.interceptors.request.use(
  config => {
    if (
      config.url === 'auth/me' || 
      config.url === 'auth/logout' || 
      config.url === `auth/addresses` || 
      config.url.includes(`auth/address`) || 
      
      config.url.includes(`auth/edit/`) || 
      config.url === `auth/${API_VERSION}/my-orders` || 
      config.url.includes(`auth/${API_VERSION}/orders`) || 
      config.url === `auth/${API_VERSION}/orders${window.location.href.split('pedido')[1]}/evaluations`){
      config.headers['Authorization'] = `Bearer ${localStorage.getItem('token_sanctum')}`      
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if ((error.response.status === 401 || error.response.status === 403) && window.location.pathname != '/login') {
      window.location = '/login'
    }

    return Promise.reject(error)


  }
)
