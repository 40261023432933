const mutations = {
  SET_COMPANIES(state, companies){
    state.items = companies
  },
  SET_COMPANY_SELECTED(state, company){
    state.companySelected = Object.assign(state.companySelected, company.data)
  },
  SET_CATEGORIES_COMPANY(state, categories){
    state.categoriesCompanySelected = categories.data  
  },
  SET_PRODUCTS_COMPANY(state, products){
    state.companySelected.products = products.data  
  },

}
export default  mutations