import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes.map'

Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode:'history',

})

router.beforeEach((to,from, next) =>{

  document.title = 'PutzFome'

  if(to.hasOwnProperty('meta')){
    document.title = to.meta.title
  }

  next()
})

export default router