import { api } from "@/bootstrap/index";
import { API_VERSION } from '@/configs/api'

const RESOURCE = 'tenants'

const actions = {
  getCompanies({ commit }) {
    return api.get(`${API_VERSION}/${RESOURCE}`)
      .then(response => {
        console.log('xxx',response);
        commit('SET_COMPANIES', response.data)
        return response
      })
  },

  getCompanySelected({ commit }, companyUrl) {
    return api.get(`${API_VERSION}/tenant/${companyUrl}`)
      .then(response => {

        commit('SET_COMPANY_SELECTED', response.data)
        return response.data
      })
  },

  getCategoriesByCompany({ commit }, token_company) {

    return api.get(`${API_VERSION}/categories`, { params: { token_company } })
      .then(response => {
        commit('SET_CATEGORIES_COMPANY', response.data)
      }).catch(error => {
      })
  },

  getProductsByCompany({ commit }, params) {
    return api.get(`${API_VERSION}/products`, { params })
      .then(response => {
        commit('SET_PRODUCTS_COMPANY', response.data)
      }).catch(error => {
      })
  },

  getTimeRestaurantOpens({ commit }, uuid) {
    return api.get(`${API_VERSION}/tenant/${uuid}/open`)
      .then(response => {
        return response.data
      })
  },


}

export default actions
